<template>
  <section class="product-modal">
    <div
      v-if="visible"
      class="product-modal__container"
    >
      <div
        class="product-modal__content"
        ref="content"
      >
        <div class="product-modal__top-wrapper">
          <h3 class="product-modal__title">{{ productName }}</h3>
          <SfButton
            class="sf-button--pure product-modal__close"
            aria-label="Close modal"
            type="button"
            data-testid="close-button"
            @click="close"
          >
            <slot name="close">
              <SfIcon icon="cross" size="1rem" color="var(--togas-black-color)" />
            </slot>
          </SfButton>
        </div>

        <div class="product-modal__content-container">
          <div class="product-modal__thumbs-wrapper desktop-only">
            <div ref="thumbs" class="swiper-container">
              <div class="swiper-wrapper">
                <div class="swiper-slide product-modal__thumb"
                     v-for="(item, i) in productGallery" :key="i"
                >
                  <SfImage
                    class="product-modal__thumbs-image"
                    :src="item.mobile"
                    :alt="item.alt"
                    height="65"
                    width="65"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="product-modal__image--wrapper">
            <div ref="images" class="swiper-container">
              <div class="swiper-wrapper">
                <div
                  class="swiper-slide product-modal__image-item"
                  v-for="(item, i) in productGallery" :key="i"
                >
                  <div class="swiper-zoom-container" :title="$t('Double click for Zoom')">
                    <img
                      class="product-modal__image-zoom img-block"
                      :src="item.source_link"
                      :alt="item.alt">
                  </div>
                </div>
              </div>
            </div>
            <transition name="fade">
              <SfImage
                v-show="currentImage === 0"
                class="product-modal__image-button mobile-only"
                :src="require('~/assets/images/ScrollDown.png')"
                alt="Scroll down"
                height="44"
                width="44"
                @click="scrollDown"
              />
            </transition>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { SfIcon, SfButton, SfImage } from '~/components';

import Swiper, { Thumbs, Keyboard, Mousewheel, Zoom } from 'swiper';

Swiper.use([Thumbs, Keyboard, Mousewheel, Zoom]);

export default {
  name: 'ProductGalleryModal',
  components: {
    SfIcon,
    SfButton,
    SfImage,
  },
  model: {
    prop: 'visible',
    event: 'close',
  },
  props: {
    isCustomGallery: {
      type: Boolean,
      required: true,
      default: false,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    productName: {
      type: String,
      default: '',
    },
    productGallery: {
      type: Array,
      default: [],
    },
    activeImage: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      thumbsSlider: null,
      imageSlider: null,
      currentImage: 0,
    };
  },
  beforeDestroy() {
    document.body.classList.remove('no-scroll');
    document.removeEventListener('keydown', this.keydownHandler);
  },
  methods: {
    close() {
      this.$emit('close', false);
    },
    keydownHandler(e) {
      if(e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27) {
        this.close();
      }
    },
    slideTo(index) {
      if(this.activeImage !== 0 && this.imageSlider) {
        this.imageSlider.slideTo(index);
      }
    },
    scrollDown() {
      this.imageSlider.slideNext(300);
    }
  },
  mounted() {
    document.body.classList.add('no-scroll');
    document.addEventListener('keydown', this.keydownHandler);

    // Swiper Docs
    // https://v6.swiperjs.com/swiper-api
    // https://v6.swiperjs.com/demos

    this.thumbsSlider = new Swiper(this.$refs.thumbs, {
      direction: 'vertical',
      slidesPerView: 'auto',
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      spaceBetween: 8,
    });

    this.imageSlider = new Swiper(this.$refs.images, {
      modules: [Thumbs],
      direction: 'vertical',
      slidesPerView: 'auto',
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      freeMode: true,
      autoHeight: true,
      preloadImages: true,
      updateOnImagesReady: true,
      mousewheel: true,
      spaceBetween: 8,
      breakpoints: {
        1024: {
          spaceBetween: 16
        },
      },
      keyboard: true,
      zoom: {
        maxRatio: 3,
      },
      thumbs: {
        swiper: this.thumbsSlider
      },
      on: {
        imagesReady: () => {
          setTimeout(() => {
            this.imageSlider.update();
            this.imageSlider.slideTo(this.activeImage);
          }, 500)
        },
        slideChange: (e) => this.currentImage = e.snapIndex,
      },
    });
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_helpers.scss";
@import "~swiper/swiper.scss";

.product-modal {

  &__container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 3;
    background-color: var(--togas-white-color);

    @include for-mobile {
      padding: var(--spacer-base) var(--spacer-sm) 0;
    }

    @include for-desktop {
      padding: var(--spacer-lg) var(--spacer-xl) 0;
    }
  }

  &__content-container {
    display: flex;
    height: 100%;
  }

  &__content {
    max-width: var(--togas-content-max-width);
    height: 100%;
    width: 100%;
    margin: 0 auto;
  }

  &__top-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: var(--spacer-sm);
    position: relative;
    z-index: 3;
  }

  &__title {
    font: var(--font-weight--medium) var(--font-size--lg) / var(--line-height--lg) var(--font-family--secondary);
  }

  &__thumbs-wrapper {
    flex-shrink: 0;
    margin-right: var(--spacer-lg);
  }

  &__thumbs {
    padding-top: 8px;
  }

  &__thumb {
    font-size: 0;
    transition: box-shadow .2s;
    width: auto;
    cursor: pointer;
    margin-top: var(--spacer-xs);
    margin-right: var(--spacer-xs);
    margin-left: var(--spacer-xs);

    &.swiper-slide-thumb-active {
      box-shadow: 0 0 0 5px white, 0 0 0 6px black;
    }
  }

  &__image--wrapper {
    height: 100%;
    flex-grow: 1;
    position: relative;

    .swiper-container {
      height: 100%;
    }

    .swiper-slide {
      font-size: 0;
      cursor: url(assets/images/zoomIn.svg), auto;

      &.swiper-slide-zoomed {
        cursor: url(assets/images/zoomOut.svg), auto;
      }
    }
  }

  &__image-button {
    position: absolute;
    bottom: 80px;
    left: calc(50% - 22px);
    z-index: 1;
    animation: ani 1.4s cubic-bezier(0.35, 0, 0.65, 1);
  }

  @keyframes ani {
    0% {
      transform: translateY(0%);
    }
    20% {
      transform: translateY(-50%);
    }
    40% {
      transform: translateY(50%);
    }
    100% {
      transform: translateY(0);
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
}
</style>
